@tailwind base;
@tailwind components;
@tailwind utilities;

.loading-xl {
    width: 8.5rem;
}

.dropdown-arrow { 
    background-image: url("data:image/svg+xml;utf8,<svg width='11' height='7' viewBox='0 0 11 7' fill='none' xmlns='http://www.w3.org/2000/svg'><path d='M1.5 0.999878L5.5 4.99988L9.5 0.999878' stroke='%2371717A' stroke-width='2' stroke-linecap='round'/></svg>");
    background-size: 0.75rem; 
    background-position: right 1rem center; 
}

.white-svg {
    filter: invert(100%) sepia(0) saturate(0) hue-rotate(93deg) brightness(103%) contrast(103%)
}

.fc-loading-image {
    background-image: url(./assets/images/FcLoading.gif);
}

.ec-loading-image {
    background-image: url(./assets/images/EcLoading.gif);
}



@layer base {
    @font-face {
        font-family: 'Sofia Pro';
        font-weight: 400;
        font-display: swap;
        src: url(assets/fonts/SofiaPro400.woff2) format('woff2');
    }
    @font-face {
        font-family: 'Sofia Pro';
        font-weight: 700;
        font-display: swap;
        src: url(assets/fonts/SofiaPro700.woff2) format('woff2');
    }
    @font-face {
        font-family: 'Sofia Pro';
        font-weight: 800;
        font-display: swap;
        src: url(assets/fonts/SofiaPro800.woff2) format('woff2');
    }
    @font-face {
        font-family: 'Fieldwork';
        font-weight: 400;
        font-display: swap;
        src: url(assets/fonts/Fieldwork400.woff2) format('woff2');
    }
    @font-face {
        font-family: 'Fieldwork';
        font-weight: 600;
        font-display: swap;
        src: url(assets/fonts/Fieldwork600.woff2) format('woff2');
    }
    @font-face {
        font-family: 'Fieldwork';
        font-weight: 700;
        font-display: swap;
        src: url(assets/fonts/Fieldwork700.woff2) format('woff2');
    }
}